<template>
  <div class="panel">
    <div class="panel-item">
      <header>Баталгаажуулах</header>
    </div>
    <div class="panel-item">
      <div class="text-color-secondary mb20">Имэйл хаягаар ирсэн 6 оронтой тоог оруулна уу</div>
      <el-form
        class="login-form"
        :model="model"
        :rules="rules"
        @submit.native.prevent="onSubmit"
        ref="form"
      >
        <el-form-item prop="code" label="Баталгаажуулах код:">
          <el-input
            v-model="model.code"
            placeholder=""
            prefix-icon="fas fa-lock-open"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="loading"
            class="login-button block"
            type="primary"
            native-type="submit"
            @click="onSubmit()"
            >Баталгаажуулах</el-button
          >
        </el-form-item>
        <div class="text-center">
          <router-link class="inventory" to="/signIn">Нэвтрэх</router-link>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { confirmSignUp } from '../../../utils/auth.js'
export default {
  name: 'verify',
  data () {
    return {
      model: {
        code: ''
      },
      loading: false,
      rules: {
        code: [
          {
            required: true,
            message: 'Баталгаажуулах код хоосон байна',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    onSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          confirmSignUp(this.$route.params.username, this.model.code).then((response) => {
            this.loading = false
            this.$router.push({
              name: 'signIn'
            })
          }).catch(err => {
            this.loading = false
            console.log(err)
          })
        } else {
          this.loading = false
          return null
        }
      })
    }
  }
}
</script>
